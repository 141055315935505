<template>
  <div>
    <v-app>
      <v-content>
        <v-card class="overflow-hidden mx-auto">
          <Navbar v-scroll="onScroll" />
          <v-content>
            <section class="mx-auto" fixed-tabs>
              <v-img
                v-if="isbgDesktop"
                :src="require('../../assets/img/bg-datausing.jpg')"
                fixed-tabs
              >
                <v-container fill-height>
                  <v-row class="mx-auto text-left mb-5">
                    <v-col cols="12" md="12">
                      <div class="slide-right white--text">
                        <h2>ข้อกำหนดการใช้บริการ One Box</h2>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-img>
            </section>
            <section class="mx-auto" fixed-tabs>
              <v-img
                v-if="isbgMobile"
                :height="$vuetify.breakpoint.smAndDown ? 700 : 500"
                :src="require('../../assets/img/bg-datausing-mobile.jpg')"
              >
                <v-container fill-height>
                  <v-row class="mx-auto text-center mb-5">
                    <v-col cols="12" md="12">
                      <div class="slide-right white--text mt-12">
                        <h2>
                          ข้อกำหนดการใช้บริการ
                          <br />One Box
                        </h2>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-img>
            </section>
            <section class="white" style="color:#1A237E">
              <v-container>
                <v-row no-gutters>
                  <v-col class="text-left pa-5" cols="12" md="12">
                    <h2>บริษัท อินเทอร์เน็ตประเทศไทย จำกัด (มหาชน)</h2>
                    <br />
                    <v-divider></v-divider>
                    <br />
                    <card-text class="swiper-image-inner mb-5" color="white">
                      วัตถุประสงค์ของข้อกำหนดการใช้งานบัญชีที่ใช้สำหรับเก็บไฟล์อิเล็กทรอนิกส์ (ข้อกำหนด)
                      มีไว้เพื่อวางข้อกำหนดและเงื่อนไข สำหรับการใช้บริการทั้งหมดที่เกี่ยวกับบัญชีที่ใช้สำหรับเก็บไฟล์อิเล็กทรอนิกส์
                      (One box) ซึ่งให้บริการโดยบริษัท อินเทอร์เน็ตประเทศไทย จำกัด (มหาชน)
                      หากผู้ใช้งานต้องการใช้ One box ผู้ใช้งานจะต้องรับผิดชอบในการปฏิบัติตามกฎหมายและข้อบังคับทั้งหมดที่เกี่ยวข้องเมื่อใช้งาน One box
                      รวมถึงข้อกำหนดการใช้บริการ One ID และนโยบายความเป็นส่วนตัว
                    </card-text>
                  </v-col>
                </v-row>
                <br />
              </v-container>
            </section>
            <section class="indigo lighten-5 pt-6 pb-7" style="color:#1A237E">
              <v-container>
                <div class="black--text" justify="center" align="center">
                  <div class="pb-5">
                    <h2>เนื้อหาของเว็บแอปพลิเคชัน</h2>
                  </div>
                  <v-container grid-list-xs>
                    <v-layout row wrap class="pb-3">
                      <v-flex d-flex xs12 sm4 md4>
                        <sequential-entrance fromRight delay="100">
                          <v-card class="mx-auto" max-width="460" :height="150" max-height="400">
                            <v-card-text style="color:#1A237E">
                              <v-row>
                                <v-col cols="12" sm="1">
                                  <v-icon color="indigo darken-4">mdi-shield-account-outline</v-icon>
                                </v-col>
                                <v-col
                                  justify="left"
                                  align="left"
                                  cols="12"
                                  sm="11"
                                >ไม่ว่าผู้ใช้งานจะเป็นบุคคลธรรมดาหรือนิติบุคคล ผู้ใช้งานจะต้องทำการสมัครขอใช้งานบัญชี One box ผ่านทางวิธีการที่บริษัทกำหนด</v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </sequential-entrance>
                      </v-flex>
                      <v-flex d-flex xs12 sm4 md4>
                        <sequential-entrance fromRight delay="100">
                          <v-card class="mx-auto" max-width="460" :height="150">
                            <v-card-text style="color:#1A237E">
                              <v-row>
                                <v-col cols="12" sm="1">
                                  <v-icon color="indigo darken-4">mdi-shield-link-variant-outline</v-icon>
                                </v-col>
                                <v-col
                                  justify="left"
                                  align="left"
                                  cols="12"
                                  sm="11"
                                >บริษัทจะอนุญาตให้อัปโหลด ดาวน์โหลด จัดเก็บ จัดส่ง รับไฟล์ในบัญชี One box ของผู้ใช้งานได้โดยผู้ใช้งานเป็นเจ้าของเนื้อหาทั้งหมด</v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </sequential-entrance>
                      </v-flex>
                      <v-flex d-flex xs12 sm4 md4>
                        <sequential-entrance fromRight delay="100">
                          <v-card class="mx-auto" max-width="460" :height="150">
                            <v-card-text style="color:#1A237E">
                              <v-row>
                                <v-col cols="12" sm="1">
                                  <v-icon color="indigo darken-4">mdi-shield-sync-outline</v-icon>
                                </v-col>
                                <v-col
                                  justify="left"
                                  align="left"
                                  cols="12"
                                  sm="11"
                                >บริษัทให้สิทธิผู้ใช้งานแชร์ไฟล์ให้กับผู้ใช้งานบัญชี One box รายอื่นได้ เนื่องจากผู้ใช้งานเป็นเจ้าของเนื้อหา</v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </sequential-entrance>
                      </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex d-flex xs12 sm4 md4>
                        <sequential-entrance fromRight delay="100">
                          <v-card class="mx-auto" max-width="460" :height="173" max-height="600">
                            <v-card-text style="color:#1A237E">
                              <v-row>
                                <v-col cols="12" sm="1">
                                  <v-icon color="indigo darken-4">mdi-shield-home-outline</v-icon>
                                </v-col>
                                <v-col
                                  justify="left"
                                  align="left"
                                  cols="12"
                                  sm="11"
                                >บริษัทไม่ต้องรับผิดชอบสำหรับความเสียหายหรือการเสียประโยชน์ที่เกิดขึ้นกับผู้ใช้งานจากการกระทำที่ได้กระทำผ่านบัญชี One box ไม่ว่าด้วยเหตุใดก็ตาม</v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </sequential-entrance>
                      </v-flex>
                      <v-flex d-flex xs12 sm4 md4>
                        <sequential-entrance fromRight delay="100">
                          <v-card class="mx-auto" max-width="460" align="center" justify="center">
                            <v-card-text style="color:#1A237E">
                              <v-row>
                                <v-col cols="12" sm="1">
                                  <v-icon color="indigo darken-4">mdi-shield-check-outline</v-icon>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="11"
                                  class="text-left"
                                >เมื่อผู้ใช้งานได้สมัครใช้งานบัญชี One box ผ่านทางวิธีการที่บริษัทกำหนดและได้รับการอนุมัติจากบริษัทแล้ว ผู้ใช้งานจะได้รับพื้นที่เก็บข้อมูล</v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn color="indigo darken-4" text>อ่านเพิ่มเติม</v-btn>

                              <v-spacer></v-spacer>

                              <v-btn icon @click="showDataUsingOne = !showDataUsingOne">
                                <v-icon>{{ showDataUsingOne ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                              </v-btn>
                            </v-card-actions>

                            <v-expand-transition>
                              <div v-show="showDataUsingOne">
                                <v-divider></v-divider>
                                <v-card-text
                                  style="color:#1A237E"
                                >ที่เป็นส่วนตัวของผู้ใช้งานทางบริษัทจะไม่มีส่วนเกี่ยวข้องและเข้าไปยุ่งเกี่ยวกับข้อมูลใน One box ของผู้ใช้งาน</v-card-text>
                              </div>
                            </v-expand-transition>
                          </v-card>
                        </sequential-entrance>
                      </v-flex>
                      <v-flex d-flex xs12 sm4 md4>
                        <sequential-entrance fromRight delay="100">
                          <v-card
                            class="mx-auto"
                            max-width="460"
                            align="center"
                            justify="center"
                            max-height="500"
                          >
                            <v-card-text style="color:#1A237E">
                              <v-row>
                                <v-col cols="12" sm="1">
                                  <v-icon color="indigo darken-4">mdi-shield-lock-outline</v-icon>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="11"
                                  class="text-left"
                                >กรณีที่สัญญาที่เกี่ยวกับการใช้งานบัญชี One box ถูกยกเลิก หรือเสร็จสมบูรณ์ หรือการให้การบริการ บัญชี One box เสร็จสมบูรณ์</v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn color="indigo darken-4" text>อ่านเพิ่มเติม</v-btn>

                              <v-spacer></v-spacer>

                              <v-btn icon @click="showDataUsingTwo = !showDataUsingTwo">
                                <v-icon>{{ showDataUsingTwo ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                              </v-btn>
                            </v-card-actions>

                            <v-expand-transition>
                              <div v-show="showDataUsingTwo">
                                <v-divider></v-divider>
                                <v-card-text
                                  style="color:#1A237E"
                                >บริษัทสามารถลบข้อมูลเกี่ยวกับบัญชี One box ที่เกี่ยวข้องและเนื้อหาที่มีการเผยแพร่ผ่านทางบัญชี One box (หมายถึง ข้อมูลหรือเนื้อหาในรูปแบบที่ผู้ใช้งานอนุญาตให้มีการส่งผ่านหรือเข้าถึงได้โดยผู้ใช้งานผ่านการใช้งานบัญชี One box รวมถึงแต่ไม่จำกัดเพียง ไอคอน ข้อมูลในโปรไฟล์ ข้อความ รูปภาพและภาพเคลื่อนไหวซึ่งส่งโดยผู้ใช้งาน ต่อไปนี้จะเรียกรวมกันว่า “เนื้อหา”) โดยดุลพินิจของบริษัท และผู้ใช้งานจะต้องให้ความยินยอมเพื่อการดำเนินการดังกล่าวด้วย</v-card-text>
                              </div>
                            </v-expand-transition>
                          </v-card>
                        </sequential-entrance>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </div>
              </v-container>
            </section>
            <section class="pt-8 pb-8">
              <v-container>
                <v-row justify="center">
                  <v-subheader>
                    <h2>ข้อมูลที่บริษัทต้องการนำไปใช้</h2>
                  </v-subheader>
                  <v-expansion-panels popout>
                    <v-expansion-panel
                      class="mb-4"
                      v-for="(message, i) in messages"
                      :key="i"
                      hide-actions
                    >
                      <v-expansion-panel-header>
                        <v-layout row>
                          <v-flex x2 lg1 order-md1 order-xs1>
                            <v-avatar size="36px">
                              <v-icon :color="message.color" v-text="message.icon"></v-icon>
                            </v-avatar>
                          </v-flex>

                          <v-flex xs8 lg9 order-md2 order-xs2>
                            <strong v-html="message.title"></strong>
                          </v-flex>

                          <v-flex xs2 lg2 order-md3 order-xs3>
                            <v-chip
                              v-if="message.new"
                              :color="`${message.color} lighten-4`"
                              class="ml-0 mr-2 black--text"
                              label
                              small
                            >{{ message.new }}</v-chip>
                          </v-flex>
                        </v-layout>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <v-divider></v-divider>
                        <v-card-text v-text=" message.detail"></v-card-text>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>
              </v-container>
            </section>
            <section class="indigo lighten-5 pt-10 pb-10">
              <v-container grid-list-xs>
                <v-layout row wrap>
                  <v-flex d-flex xs12 sm3 md3 class="pr-3">
                    <v-img :src="require('../../assets/img/image9.png')" width="5" />
                  </v-flex>
                  <v-flex d-flex xs12 sm8 md9>
                    <v-layout row wrap>
                      <v-flex d-flex>
                        <v-layout row wrap>
                          <v-flex class="pb-3" d-flex xs12>
                            <v-container grid-list-xs>
                              <sequential-entrance fromRight delay="100">
                                <v-card>
                                  <v-card-title primary-title>การปรับปรุงข้อกำหนด</v-card-title>
                                  <v-card-text>
                                    บริษัทสามารถปรับปรุงข้อกำหนดนี้และลักษณะของการบริการ และอื่น ๆ ที่ให้บริการโดยวัน
                                    บ็อกซ์ได้โดยแจ้งให้ผู้ใช้งานทราบถึงการปรับปรุงดังกล่าวโดยการประกาศหรือการบอกกล่าวตามที่บริษัทเห็นว่า
                                    จำเป็นโดยดุลพินิจฝ่ายเดียวของบริษัทหรือตามที่กฎหมายที่เกี่ยวข้องกำหนด ในกรณีที่ผู้ใช้งานยังคงใช้งานวัน
                                    บ็อกซ์ต่อไปหลังจากการปรับปรุง ให้ถือว่าผู้ใช้งานดังกล่าวได้ให้ความยินยอมในการปรับปรุงข้อกำหนดนี้และลักษณะการบริการ และอื่น ๆ แล้ว
                                  </v-card-text>
                                </v-card>
                              </sequential-entrance>
                            </v-container>
                          </v-flex>
                          <v-flex d-flex xs12>
                            <v-container grid-list-xs>
                              <sequential-entrance fromRight delay="100">
                                <v-card>
                                  <v-card-title primary-title>ระยะเวลาการใช้งาน</v-card-title>
                                  <v-card-text>
                                    ระยะเวลาใช้งานสำหรับบัญชี One box จะเริ่มต้นตั้งแต่วันที่ผู้ใช้งานมีคำขอใช้งานบัญชีดังกล่าวจนถึงวันที่บัญชีนั้นถูกลบอย่างเสร็จสมบูรณ์
                                    โดยบริษัทจะเก็บรักษาข้อมูลที่ผู้ใช้งานจัดเก็บไว้บนวัน บ๊อกซ์ของบริษัทตราบเท่าที่บัญชีของคุณยังคงอยู่
                                    หรือตราบเท่าที่บริษัทต้องการข้อมูลเพื่อให้บริการแก่ผู้ใช้งาน หากผู้ใช้งานลบบัญชีของตนเองหรือถูกระงับการใช้งานบัญชี
                                    บริษัทจะเริ่มการลบข้อมูลนี้หลังจาก 14 วัน
                                  </v-card-text>
                                </v-card>
                              </sequential-entrance>
                            </v-container>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-container>
            </section>
            <section class="white pt-3 pb-3" style="color:#1A237E">
              <v-container>
                <v-row no-gutters>
                  <v-col class="text-center pa-12" cols="12" sm="3">
                    <v-row class="fill-height" align="center" justify="center">
                      <v-col cols="12">
                        <h2>ค่าบริการ</h2>
                        <br />
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" sm="9" class="fill-height">
                    <v-row>
                      <v-col cols="12" sm="4">
                        <sequential-entrance fromRight delay="100">
                          <v-container grid-list-xs>
                            <v-card
                              class="mx-auto"
                              max-width="300"
                              height="170"
                              align="left"
                              justify="left"
                            >
                              <v-card-text>
                                <div
                                  class="text--primary"
                                >บริษัทสามารถเปลี่ยนแปลงหรือเพิ่มเติม แผนการบริการที่ต้องชำระค่าบริการโดยแจ้งให้ผู้ใช้งานทราบโดยการประกาศหรือบอกกล่าวเกี่ยวกับเรื่องดังกล่าว</div>
                              </v-card-text>
                            </v-card>
                          </v-container>
                        </sequential-entrance>
                        <br />
                        <sequential-entrance fromRight delay="100">
                          <v-container grid-list-xs>
                            <v-card class="mx-auto" max-width="300" align="left" justify="left">
                              <v-card-text>
                                <div class="text--primary">
                                  แม้ว่าผู้ใช้งานจะได้ทำการยกเลิกบัญชี
                                  <br />One box สำหรับแผนการบริการที่ต้องชำระค่าบริการภายในเดือนใด
                                </div>
                              </v-card-text>
                              <v-card-actions>
                                <v-btn color="indigo darken-4" text>อ่านเพิ่มเติม</v-btn>

                                <v-spacer></v-spacer>

                                <v-btn icon @click="showDataThree = !showDataThree">
                                  <v-icon>{{ showDataThree ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                </v-btn>
                              </v-card-actions>

                              <v-expand-transition>
                                <div v-show="showDataThree">
                                  <v-divider></v-divider>
                                  <v-card-text>
                                    แล้วก็ตามผู้ใช้งานดังกล่าวยังจะต้องชำระค่าบริการรายเดือนสำหรับเดือนดังกล่าวเต็มจำนวน
                                    และค่าบริการดังกล่าวจะไม่ถูกคำนวณเป็นรายวัน นอกจากนี้ แม้ว่าบัญชีจะถูกยกเลิกแล้ว
                                    บริษัทจะไม่คืนค่าบริการที่ผู้ใช้งานได้ชำระไว้ล่วงหน้าให้แก่ผู้ใช้งาน
                                  </v-card-text>
                                </div>
                              </v-expand-transition>
                            </v-card>
                          </v-container>
                        </sequential-entrance>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <sequential-entrance fromRight delay="100">
                          <v-container grid-list-xs>
                            <v-card class="mx-auto" max-width="300" align="left" justify="left">
                              <v-card-text>
                                <div class="text--primary">
                                  บริษัทจะกำหนดลักษณะ ค่าบริการ วันที่
                                  <br />ถึงกำหนดชำระค่าบริการ สำหรับแผนการบริการที่ต้องชำระค่าบริการ
                                </div>
                              </v-card-text>
                              <v-card-actions>
                                <v-btn color="indigo darken-4" text>อ่านเพิ่มเติม</v-btn>

                                <v-spacer></v-spacer>

                                <v-btn icon @click="showDataOne = !showDataOne">
                                  <v-icon>{{ showDataOne ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                </v-btn>
                              </v-card-actions>

                              <v-expand-transition>
                                <div v-show="showDataOne">
                                  <v-divider></v-divider>
                                  <v-card-text>
                                    และอื่น ๆ
                                    และจะประกาศหรือบอกกล่าวผู้ใช้งานเกี่ยวกับเรื่องดังกล่าว
                                    โปรดตรวจสอบเรื่องดังกล่าวข้างต้นในเวลาที่สมัครใช้งานแผนการบริการที่ต้องชำระค่าบริการ
                                  </v-card-text>
                                </div>
                              </v-expand-transition>
                            </v-card>
                          </v-container>
                        </sequential-entrance>
                        <br />
                        <sequential-entrance fromRight delay="100">
                          <v-container grid-list-xs>
                            <v-card class="mx-auto" max-width="300" align="left" justify="left">
                              <v-card-text>
                                <div
                                  class="text--primary"
                                >ในเวลาที่ชำระค่าบริการให้แก่บริษัท หากค่าบริการดังกล่าวจะต้องเสียภาษีการบริโภคตามบทบัญญัติ</div>
                              </v-card-text>
                              <v-card-actions>
                                <v-btn color="indigo darken-4" text>อ่านเพิ่มเติม</v-btn>

                                <v-spacer></v-spacer>

                                <v-btn icon @click="showDataFour = !showDataFour">
                                  <v-icon>{{ showDataFour ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                </v-btn>
                              </v-card-actions>

                              <v-expand-transition>
                                <div v-show="showDataFour">
                                  <v-divider></v-divider>
                                  <v-card-text>
                                    ของพระราชบัญญัติภาษีธุรกิจว่าด้วยภาษีมูลค่าเพิ่มและที่ไม่ใช่ภาษีมูลค่าเพิ่ม (Value-added and Non-value-added Business Tax Act) รวมถึงกฎหมาย
                                    และข้อบังคับที่เกี่ยวข้องกับพระราชบัญญัติดังกล่าว หรือกฎหมายหรือข้อบังคับใด ๆ ที่เกี่ยวข้อง ผู้ใช้งานจะต้องชำระค่าบริการนั้นพร้อมด้วยจำนวนเงินซึ่งเท่ากับภาษีที่เกี่ยวข้อง
                                  </v-card-text>
                                </div>
                              </v-expand-transition>
                            </v-card>
                          </v-container>
                        </sequential-entrance>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <sequential-entrance fromRight delay="100">
                          <v-container grid-list-xs>
                            <v-card class="mx-auto" max-width="300" align="left" justify="left">
                              <v-card-text>
                                <div
                                  class="text--primary"
                                >เมื่อผู้ใช้งานประสงค์จะเปลี่ยนแปลงแผนการบริการจากแบบไม่มีค่าบริการเป็นแผนการบริการที่ต้องชำระค่าบริการ</div>
                              </v-card-text>
                              <v-card-actions>
                                <v-btn color="indigo darken-4" text>อ่านเพิ่มเติม</v-btn>

                                <v-spacer></v-spacer>

                                <v-btn icon @click="showDataTwo = !showDataTwo">
                                  <v-icon>{{ showDataTwo ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                </v-btn>
                              </v-card-actions>

                              <v-expand-transition>
                                <div v-show="showDataTwo">
                                  <v-divider></v-divider>
                                  <v-card-text>
                                    ค่าบริการ ผู้ใช้งานดังกล่าวจะต้องมีคำขอเปลี่ยนแปลงดังกล่าวผ่านทางวิธีการที่บริษัทกำหนดขึ้น
                                    และผู้ใช้งาน จะสามารถทำการเปลี่ยนแปลงดังกล่าวได้ในวันที่บริษัทได้รับคำขอเช่นว่านั้นแล้ว
                                    นอกจากนี้ เมื่อผู้ใช้งานประสงค์จะเปลี่ยนแปลงจากแผนการบริการที่ต้องชำระค่าบริการแผนหนึ่งเป็นอีกแผนหนึ่ง
                                    หรือเป็นแบบไม่มีค่าบริการ ผู้ใช้งานจะสามารถทำการเปลี่ยนแปลงดังกล่าวได้ในเดือนถัดจากวันที่บริษัทได้รับคำขอเปลี่ยนแปลงเช่นว่านั้น
                                  </v-card-text>
                                </div>
                              </v-expand-transition>
                            </v-card>
                          </v-container>
                        </sequential-entrance>
                        <br />
                        <sequential-entrance fromRight delay="100">
                          <div align="center" justify="center">
                            <v-img :src="require('../../assets/img/image8.png')" width="70%" />
                          </div>
                        </sequential-entrance>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </section>
            <section class="indigo lighten-5 pt-5 pb-5">
              <v-container grid-list-xs>
                <v-container grid-list-xs>
                  <v-container grid-list-xs>
                    <h2 class="text-left pb-5">ความรับผิดชอบของผู้ใช้งาน</h2>
                  </v-container>
                  <v-layout row wrap>
                    <v-flex d-flex xs12 sm8 md9>
                      <v-layout row wrap>
                        <v-flex d-flex>
                          <v-layout row wrap>
                            <v-flex d-flex xs12>
                              <v-container grid-list-xs>
                                <sequential-entrance fromRight delay="100">
                                  <v-expansion-panels inset>
                                    <v-expansion-panel
                                      class="mb-3"
                                      v-for="(item,i) in items"
                                      :key="i"
                                    >
                                      <v-expansion-panel-header>{{item.title}}</v-expansion-panel-header>
                                      <v-expansion-panel-content>{{item.description}}</v-expansion-panel-content>
                                    </v-expansion-panel>
                                  </v-expansion-panels>
                                </sequential-entrance>
                              </v-container>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex d-flex xs12 sm3 md3 class="pr-3" align="center">
                      <v-container grid-list-xs>
                        <v-img :src="require('../../assets/img/image7.png')" />
                      </v-container>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-container>
            </section>
            <section class="white pt-5 pb-3" style="color:#1A237E">
              <v-container>
                <v-row dense>
                  <v-col class="pt-2" cols="12" sm="2" align="center">
                    <v-container grid-list-xs>
                      <v-img :src="require('../../assets/img/image5.png')" width="100%" />
                    </v-container>
                  </v-col>
                  <v-col cols="12" sm="10">
                    <v-container grid-list-xs>
                      <h3 class="pb-3">การโอนและพันธมิตรทางธุรกิจ</h3>บริษัทสามารถให้ข้อมูลเกี่ยวกับบัญชี One box
                      แก่คู่ค้าทางธุรกิจและบุคคลที่สามอื่นๆ เกี่ยวกับผู้ใช้งานเพื่อจัดให้ซึ่งการทำงานของบัญชี One box แก่คู่ค้าทางธุรกิจและเว็บไซต์
                      แอพพลิเคชั่น และอื่นๆ เป็นต้น ของบุคคลที่สามอื่นๆ ในการนี้ การเชื่อมโยงของผู้ใช้งานากับบัญชี One box
                      และอื่นๆ อาจถูกแสดงอยู่บนเว็บไซต์ของคู่ค้าทางธุรกิจหรือบุคคลที่สามอื่นๆ ก็ได้
                      ยิ่งไปกว่านั้น บริษัทสามารถแสดงตัวชี้แหล่งในอินเทอร์เน็ตของเว็บไซต์ (URL)
                      ซึ่งรวมถึงข้อมูลของผู้ใช้งานไว้ที่หุ้นส่วนทางธุรกิจ เผยแพร่การเชื่อมต่อสู่เว็บไซต์ดังกล่าว เป็นต้น
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </section>
            <section class="indigo lighten-5 pt-8 pb-10" style="color:#1A237E">
              <v-container grid-list-xs>
                <div justify="center" align="center">
                  <v-container grid-list-xs>
                    <v-layout row wrap class="pb-3">
                      <v-container grid-list-xs class="text-left">
                        <h2>การระงับ การเปลี่ยนแปลง และการเสร็จสมบูรณ์ของการบริการ</h2>
                      </v-container>
                      <v-flex d-flex lg9 xs12 sm4 md4 class="text-left">
                        <v-container grid-list-xs>
                          ในกรณีของข้อใดข้อหนึ่งดังต่อไปนี้ บริษัทสามารถระงับการให้การบริการบัญชี One box ได้ในบางกรณี
                          ถึงแม้ว่าบริษัท
                          จะได้ระงับบัญชี One box เป็นการชั่วคราว บริษัทจะไม่รับผิดชอบใด ๆ สำหรับการระงับดังกล่าวต่อผู้ใช้งาน
                        </v-container>
                      </v-flex>
                      <v-flex d-flex lg3 xs12 sm4 md4>
                        <v-card
                          class="mx-auto"
                          max-width="520"
                          max-height="300"
                          style="color:#1A237E"
                        >
                          <v-row>
                            <v-col cols="12" sm="1">
                              <v-container grid-list-xs>
                                <v-icon color="indigo darken-4">mdi-circle-slice-1</v-icon>
                              </v-container>
                            </v-col>
                            <v-col cols="12" sm="11" justify="left" align="left">
                              <v-container grid-list-xs>
                                กรณีของการบำรุงรักษา ตรวจสอบ หรือเรื่องอื่นในทำนองเดียวกัน
                                เกี่ยวกับสิ่งอำนวยความหรืออุปกรณ์ที่จำเป็นสำหรับการให้การบริการบัญชีทางการ
                                ซึ่งได้มีขึ้นตามปกติหรือในกรณีฉุกเฉิน
                              </v-container>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex d-flex lg3 xs12 sm4 md4>
                        <v-card
                          class="mx-auto"
                          max-width="520"
                          max-height="300"
                          style="color:#1A237E"
                        >
                          <v-row>
                            <v-col cols="12" sm="1">
                              <v-container grid-list-xs>
                                <v-icon color="indigo darken-4">mdi-circle-slice-2</v-icon>
                              </v-container>
                            </v-col>
                            <v-col cols="12" sm="11" align="left">
                              <v-container
                                grid-list-xs
                              >กรณีที่มีความล้มเหลว การหยุดทำงาน หรือเรื่องอื่นในทำนองเดียวกัน เกิดขึ้นกับสิ่งอำนวยความสะดวกหรืออุปกรณ์ที่ใช้ในการให้การบริการบัญชี One box</v-container>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-flex>
                      <v-flex d-flex lg3 xs12 sm4 md4>
                        <v-card
                          class="mx-auto"
                          max-width="520"
                          max-height="300"
                          style="color:#1A237E"
                        >
                          <v-row>
                            <v-col cols="12" sm="1">
                              <v-container grid-list-xs>
                                <v-icon color="indigo darken-4">mdi-circle-slice-3</v-icon>
                              </v-container>
                            </v-col>
                            <v-col cols="12" sm="11" align="left">
                              <v-container
                                grid-list-xs
                              >กรณีที่เป็นไปไม่ได้ที่จะใช้งานการบริการโทรคมนาคมซึ่งดำเนินการโดยผู้ประกอบธุรกิจโทรคมนาคม</v-container>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-flex>
                      <v-flex d-flex lg3 xs12 sm4 md4>
                        <v-card
                          class="mx-auto"
                          max-width="520"
                          max-height="300"
                          style="color:#1A237E"
                        >
                          <v-row>
                            <v-col cols="12" sm="1">
                              <v-container grid-list-xs>
                                <v-icon color="indigo darken-4">mdi-circle-slice-4</v-icon>
                              </v-container>
                            </v-col>
                            <v-col cols="12" sm="11" align="left">
                              <v-container
                                grid-list-xs
                              >กรณีที่มีความยุ่งยากในการให้การบริการบัญชี One box เนื่องจากไฟดับ ไฟไหม้ แผ่นดินไหวการประท้วงของแรงงาน หรือเหตุสุดวิสัยในรูปแบบอื่น</v-container>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-flex>
                      <v-flex d-flex lg3 xs12 sm4 md4>
                        <v-card
                          class="mx-auto"
                          max-width="520"
                          max-height="300"
                          style="color:#1A237E"
                        >
                          <v-row>
                            <v-col cols="12" sm="1">
                              <v-container grid-list-xs>
                                <v-icon color="indigo darken-4">mdi-circle-slice-5</v-icon>
                              </v-container>
                            </v-col>
                            <v-col cols="12" sm="11" align="left">
                              <v-container
                                grid-list-xs
                              >กรณีที่มีสาเหตุเกี่ยวกับการดำเนินการหรือสาเหตุทางเทคนิคที่เกี่ยวข้องกับบัญชี One box อย่างสมเหตุสมผล</v-container>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-flex>
                    </v-layout>
                    <div class="text-left pt-5">
                      <v-container grid-list-xs>
                        <h4>
                          บริษัทสามารถเปลี่ยนแปลงหรือสิ้นสุดการดำเนินการบัญชี One box ทั้งหมดหรือบางส่วนได้โดยการประกาศหรือแจ้งให้ผู้ใช้งานทราบในเรื่องดังกล่าว
                          แม้ว่าบริษัทจะได้เปลี่ยนแปลงหรือสิ้นสุดการดำเนินการบัญชี One box แล้วไม่ว่าทั้งหมดหรือบางส่วน บริษัทจะไม่รับผิดชอบใดๆ ในเรื่องดังกล่าวต่อผู้ใช้งานทั้งสิ้น
                        </h4>
                      </v-container>
                    </div>
                  </v-container>
                </div>
              </v-container>
            </section>
            <section class="white black--text pb-10">
              <v-container>
                <h2 class="text-center pa-10">ความเสียหาย</h2>
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <v-container>
                      <v-hover>
                        <template v-slot="{ hover }">
                          <v-card
                            :elevation="hover ? 24 : 6"
                            class="mx-auto"
                            max-width="500"
                            height="348"
                            max-height="500"
                          >
                            <v-img
                              :src="require('../../assets/img/image11.png')"
                              height="210px"
                              width="100%"
                            ></v-img>
                            <v-card-subtitle>
                              <div
                                class="text--primary pt-3"
                              >กรณีที่ผู้ใช้งานได้ละเมิดข้อกำหนดนี้และก่อให้เกิดความเสียหายแก่บริษัท ผู้ใช้งานจะต้องรับผิดชดใช้ความเสียหายนั้นให้แก่บริษัทโดยทันที</div>
                            </v-card-subtitle>
                          </v-card>
                        </template>
                      </v-hover>
                    </v-container>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-container grid-list-xs>
                      <v-hover>
                        <template v-slot="{ hover }">
                          <v-card
                            :elevation="hover ? 24 : 6"
                            class="mx-auto"
                            max-width="500"
                            align="left"
                            justify="left"
                          >
                            <v-img
                              :src="require('../../assets/img/image12.png')"
                              height="210px"
                              width="100%"
                            ></v-img>

                            <v-card-text>
                              <div class="text--primary pt-3">
                                กรณีที่ผู้ใช้งานได้รับการการร้องเรียน การอ้างสิทธิ การร้องขอ การเรียกร้อง การคัดค้าน และอื่นๆ เป็นต้น
                                จากบุคคลที่สาม เกี่ยวกับบัญชี One box
                              </div>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn color="indigo darken-4" text>อ่านเพิ่มเติม</v-btn>

                              <v-spacer></v-spacer>

                              <v-btn icon @click="showDataFive = !showDataFive">
                                <v-icon>{{ showDataFive ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                              </v-btn>
                            </v-card-actions>

                            <v-expand-transition>
                              <div v-show="showDataFive">
                                <v-divider></v-divider>
                                <v-card-text>
                                  โดยอ้างว่าสิทธิของบุคคลที่สามนั้นถูกละเมิด
                                  ผู้ใช้งานดังกล่าวจะต้องดำเนินการและแก้ไขการร้องเรียน และอื่น ๆ นั้นด้วยค่าใช้จ่ายและความรับผิดชอบของตน
                                  ทั้งนี้ ผู้ใช้งานต้องรับผิดชอบในความรับผิดทางกฎหมายใด ๆ ที่เกิดขึ้นจากการใช้งานบัญชี One boxโดยลูกค้าด้วยตนเอง
                                  และผู้ใช้งานตกลงว่าบริษัทจะไม่ต้องรับผิดสำหรับความเสียหายและการรับผิดใด ๆ ซึ่งเกิดขึ้นจากการใช้งานบัญชี One box
                                  โดยผู้ใช้งานไม่ว่าในกรณีใดก็ตาม ซึ่งในกรณีที่มีความเสียหายเกิดขึ้นต่อบริษัทที่เกี่ยวข้องกับการร้องเรียน และอื่น ๆ
                                  ผู้ใช้งานจะต้องชดใช้ความเสียหายดังกล่าวทั้งหมดให้แก่บริษัท นอกจากนี้ ในกรณีที่บริษัทดำเนินการหรือแก้ไขการร้องเรียน
                                  และอื่น ๆ ดังกล่าวในนามของผู้ใช้งานที่เกี่ยวข้อง ผู้ใช้งานที่เกี่ยวข้องต้องรับผิดชอบค่าใช้จ่ายทั้งหมดสำหรับการดำเนินการและการแก้ไขนั้นด้วย
                                </v-card-text>
                              </div>
                            </v-expand-transition>
                          </v-card>
                        </template>
                      </v-hover>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </section>
            <section class="indigo lighten-5 pt-5 pb-3" style="color:#1A237E">
              <v-container>
                <v-row dense>
                  <v-col cols="12" sm="2" align="center" justify="center">
                    <v-container grid-list-xs>
                      <v-img :src="require('../../assets/img/image13.png')" width="80%" />
                    </v-container>
                  </v-col>
                  <v-col cols="12" sm="10">
                    <v-container grid-list-xs>
                      <h3 class="pb-3">การรักษาข้อมูลความลับ</h3>ทั้งสองฝ่ายตกลงที่จะดูแลรักษาข้อมูลความลับของอีกฝ่ายเพื่อให้เป็นความลับต่อไป
                      จะไม่เปิดเผยข้อมูลความลับหรือยินยอมให้บุคคลอื่นรับทราบ หรือใช้ข้อมูลความลับ โดยจะใช้มาตรฐานการดูแลข้อมูลความลับนั้นเสมือนกับ
                      การดูแลรักษาข้อมูลความลับที่สุดของตนเองเป็นอย่างน้อย เว้นแต่ จะได้รับความยินยอมเป็นลายลักษณ์อักษรจากอีกฝ่ายหนึ่ง และเป็นการขอข้อมูลตามที่กฎหมายกำหนด
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </section>
            <section class="pt-5 pb-5">
              <v-container grid-list-xs>
                <div justify="center" align="center" style="color:#1A237E">
                  <v-row>
                    <v-col cols="12" sm="9">
                      <v-row>
                        <v-col cols="12" sm="12" align="left" justify="left">
                          <v-container grid-list-xs>
                            <h2>การระงับการใช้และการบอกเลิก</h2>ในกรณีที่บริษัทเห็นว่าข้อใดข้อหนึ่งดังต่อไปนี้เกิดขึ้นกับลูกค้าหรืออาจจะเกิดขึ้นกับผู้ใช้งาน
                            บริษัทสามารถระงับบัญชี One box โดยการระงับการใช้งานบัญชี One box โดยลูกค้าเป็นการชั่วคราว
                            หรือบอกเลิกสัญญาต่างๆ กับลูกค้าภายใต้ข้อกำหนดนี้
                            โดยไม่จำเป็นต้องแจ้งให้ทราบแต่อย่างใด และหากมีความเสียหายเกิดขึ้นแก่ผู้ใช้งานจากการระงับ
                            การใช้งานหรือการบอกเลิกสัญญาดังกล่าว บริษัทจะไม่รับผิดชอบใดๆ ในเรื่องดังกล่าวแก่ผู้ใช้งานนั้น
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-container grid-list-xs>
                            <sequential-entrance fromRight delay="100">
                              <v-card class="mx-auto" max-width="350" max-height="200">
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" sm="2">
                                      <v-icon
                                        large
                                        color="indigo darken-4"
                                      >mdi-clock-time-one-outline</v-icon>
                                    </v-col>
                                    <v-col
                                      cols="12"
                                      sm="10"
                                      style="color:#1A237E"
                                      class="text-left"
                                    >
                                      <v-container
                                        grid-list-xs
                                      >กรณีที่ผู้ใช้งานได้ใช้บัญชีทางการสำหรับวัตถุประสงค์ที่ไม่ชอบด้วยกฎหมาย</v-container>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card>
                            </sequential-entrance>
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="3">
                      <v-container grid-list-xs>
                        <sequential-entrance fromRight delay="100">
                          <v-card class="mx-auto" max-width="350" max-height="200" :height="142">
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="2">
                                  <v-icon large color="indigo darken-4">mdi-clock-time-two-outline</v-icon>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="10"
                                  style="color:#1A237E"
                                  class="text-left"
                                >กรณีที่บริษัทเชื่อว่ามีการขาดความน่าเชื่อถือของผู้ใช้งาน</v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </sequential-entrance>
                      </v-container>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-container grid-list-xs>
                        <sequential-entrance fromRight delay="100">
                          <v-card class="mx-auto" max-width="350" max-height="200" :height="142">
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="2">
                                  <v-icon large color="indigo darken-4">mdi-clock-time-three-outline</v-icon>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="10"
                                  style="color:#1A237E"
                                  class="text-left"
                                >กรณีที่ไม่สามารถติดต่อผู้ใช้งานได้ด้วยสาเหตุที่ไม่ใช่ความผิดของบริษัท</v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </sequential-entrance>
                      </v-container>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-container grid-list-xs>
                        <sequential-entrance fromRight delay="100">
                          <v-card class="mx-auto" max-width="350" max-height="200" :height="142">
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="2">
                                  <v-icon large color="indigo darken-4">mdi-clock-time-four-outline</v-icon>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="10"
                                  style="color:#1A237E"
                                  class="text-left"
                                >กรณีที่ผู้ใช้งานไม่เข้าสู่บัญชี One boxของตนภายในระยะเวลาหนึ่งที่กำหนดขึ้นโดยบริษัท</v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </sequential-entrance>
                      </v-container>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-container grid-list-xs>
                        <sequential-entrance fromRight delay="100">
                          <v-card class="mx-auto" max-width="350" max-height="200">
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="2">
                                  <v-icon large color="indigo darken-4">mdi-clock-time-five-outline</v-icon>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="10"
                                  style="color:#1A237E"
                                  class="text-left"
                                >นอกเหนือจากกรณีที่ได้ระบุไว้ข้างต้น ในกรณีที่ผู้ใช้งานได้ดำเนินการที่บริษัทเห็นว่าไม่เหมาะสม</v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </sequential-entrance>
                      </v-container>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </section>
            <section class="indigo lighten-5 pt-7 pb-7" style="color:#1A237E">
              <v-container>
                <v-row dense>
                  <v-col cols="12" sm="10" class="text-left">
                    <v-container grid-list-xs>
                      <h3 class="pb-3">สิทธิในทรัพย์สินทางปัญญา</h3>ผู้ใช้งานยังคงความเป็นเจ้าของสิทธิ์ในทรัพย์สินทางปัญญาใดๆ ที่ผู้ใช้งานถือสิทธิ์ในเนื้อหานั้นๆ
                      บริษัทจะไม่อ้างกรรมสิทธิ์ในเนื้อหาใดๆ ของผู้ใช้งาน ซึ่งรวมถึงข้อความ ข้อมูล และไฟล์ที่ผู้ใช้งานอัปโหลด
                      แชร์ หรือเก็บไว้ในบัญชีวัน บ๊อกซ์ อย่างไรก็ตามเนื้อหาในบริการอาจได้รับการคุ้มครองโดยสิทธิ์ในทรัพย์สินทางปัญญาของผู้อื่น
                      หากผู้ใช้งานไม่มีสิทธิ์ในการกระทำดังกล่าว โปรดอย่าคัดลอก อัปโหลด ดาวน์โหลด หรือแบ่งปันเนื้อหา
                      บริษัทสงวนสิทธิ์ในการลบหรือหยุดใช้งานเนื้อหาที่ถูกกล่าวหาว่าละเมิดและยุติบัญชีของผู้ที่ละเมิดอยู่ต่อเนื่อง
                      ตัวแทนที่ได้รับมอบหมายของเราสำหรับประกาศการละเมิดลิขสิทธิ์ที่ถูกกล่าวหาบนบริการ
                    </v-container>
                  </v-col>
                  <v-col cols="12" sm="2" align="center" justify="center">
                    <v-container grid-list-xs>
                      <v-img :src="require('../../assets/img/image6.png')" width="75%" />
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </section>
          </v-content>

          <Footer />
        </v-card>
      </v-content>
    </v-app>
  </div>
</template>

<script>
import Navbar from "./layout-landingpage-navbar";
import Footer from "./layout-landingpage-footer";

export default {
  components: { Footer, Navbar },
  data: () => ({
    showDataOne: false,
    showDataTwo: false,
    showDataThree: false,
    showDataFour: false,
    showDataFive: false,
    showDataUsingOne: false,
    showDataUsingTwo: false,
    isbgDesktop: true,
    isbgMobile: false,
    messages: [
      {
        color: "red",
        icon: "mdi-progress-check",
        new: "อ่านเพิ่มเติม",
        title:
          "เมื่อท่านสร้างบัญชี One box  เพื่อเข้าใช้บริการ บริษัทจะขอให้ท่านระบุหมายเลขโทรศัพท์ของท่าน ข้อมูลนี้เป็นข้อมูลที่จำเป็น ",
        detail:
          "เนื่องจากบริษัทอาจส่งข้อความผ่านเอสเอ็มเอสไปยังหมายเลขโทรศัพท์ที่ท่านระบุเพื่อตรวจจับและป้องกันอย่างต่อเนื่อง มิให้มีการใช้บริการโดยไม่ได้รับอนุญาต หรือใช้ในลักษณะฉ้อฉล หรือในทางมิชอบ นอกจากนี้ บริษัทอาจใช้หมายเลขโทรศัพท์ที่ท่านลงทะเบียนนี้เพื่อส่งข้อมูลสำคัญเกี่ยวกับบริการให้แก่ผู้ใช้งานอีกด้วย"
      },
      {
        color: "teal",
        icon: "mdi-progress-check",
        new: "อ่านเพิ่มเติม",
        title:
          "เมื่อท่านใช้บริการของบริษัท บริษัทอาจขอรับและเก็บรักษาข้อมูลแสดงตัวตนทางอิเล็กทรอนิกส์ หรือบันทึกข้อมูลที่ได้มาโดยอัตโนมัติ",
        detail:
          "จากอุปกรณ์ของท่านบางรายการ อาทิเช่น ประเภทอุปกรณ์ ประเภทระบบปฏิบัติการ เลขที่อยู่ไอพี ข้อมูลเบราว์เซอร์ ข้อมูลคุกกี้ ตัวระบุโฆษณา หรือตัวระบุแอปพลิเคชันบนโทรศัพท์เคลื่อนที่ ข้อมูลนี้เป็นข้อมูลที่จำเป็นสำหรับบริษัทในการตรวจจับและป้องกันอย่างต่อเนื่องมิให้มีการใช้บริการโดยไม่ได้รับอนุญาต หรือใช้ในลักษณะฉ้อฉล หรือในทางมิชอบ ตลอดจนเพื่อให้บริการที่ผู้ใช้งานสามารถกำหนดเองได้และให้มีประสิทธิภาพมากที่สุดกับอุปกรณ์ของผู้ใช้งาน นอกจากนี้ ขอเรียนให้ทราบว่า ตัวระบุโฆษณาของบุคคลภายนอกอาจเชื่อมโยงกับบัญชีของผู้ใช้งานผ่านตัวระบุภายในของบริษัท"
      }
    ],
    items: [
      {
        title:
          "ในกรณีที่บุคคลที่สามได้มีการร้องเรียนหรือเรียกร้อง หรือยื่นฟ้องคดี และอื่น ๆ ต่อบริษัทเนื่องจากการใช้งาน One box",
        description:
          "โดยผู้ใช้งาน ผู้ใช้งานจะต้องตอบสนองต่อเรื่องดังกล่าวด้วยค่าใช้จ่ายของตนเองและต้องรับผิดชอบแต่เพียงฝ่ายเดียว และผู้ใช้งานจะต้องไม่สร้างความยุ่งยากให้แก่บริษัทเกี่ยวกับเรื่องดังกล่าว ทั้งนี้ ผู้ใช้งานจะต้องรับผิดชอบต่อความรับผิดทางกฎหมายใด ๆ ที่เกิดขึ้นจากการใช้งานบัญชีOne boxโดยผู้ใช้งาน ซึ่งผู้บริหารจัดการ (administrators) และบุคลากรผู้ดำเนินการบัญชีOne boxจะต้องรับผิดร่วมกันและแทนกันในเรื่องดังกล่าวด้วย ทั้งนี้ ผู้ใช้งานตกลงว่าบริษัทจะไม่ต้องรับผิดชอบ ในความเสียหายหรือความรับผิดใด ๆ ที่เกิดขึ้นแก่บุคคลที่สามใด ๆ ซึ่งเกิดจากการใช้งานบัญชี One box"
      },
      {
        title:
          "หากผู้ใช้งานละเมิดสิทธิใด ๆ หรือก่อให้เกิดความเสียหายใด ๆ แก่บุคคลที่สาม โดยการฝ่าฝืนกฎหมายที่เกี่ยวข้อง หรือละเมิด",
        description:
          "ข้อกำหนดนี้บริษัทสามารถจำกัดหรือห้ามผู้บริหารจัดการหรือบุคลากรผู้ดำเนินการบัญชี One box ดังกล่าว มิให้ดำเนินการ One box อื่นใดที่มีอยู่อีกต่อไป และปฏิเสธการขอใช้บริการบัญชี One box โดยผู้บริหารจัดการหรือบุคลากรผู้ดำเนินการบัญชีOne boxที่จะมีขึ้นใหม่ในอนาคตโดยไม่ต้องบอกกล่าวล่วงหน้าหรือชี้แจงเหตุผล"
      },
      {
        title:
          "ผู้ใช้งานต้องชดใช้ความเสียหายใด ๆ (ค่าทนายความที่เกิดขึ้น) ต่อบริษัทซึ่งเกิดขึ้นจากการใช้งานบัญชี One box โดยลูกค้าโดยทันที"
      }
    ]
  }),
  methods: {
    onScroll() {
      const showsize = window.innerWidth;
      this.isbgDesktop = showsize > 600;
      this.isbgMobile = showsize < 600;
    }
  }
};
</script>

<style>
@import "../../assets/css/data.css";
</style>
